// Feedback.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Feedback = ({ header, onSubmit }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [experience, setExperience] = useState('');
  const [recommendations, setRecommendations] = useState('');

  const handleSubmit = () => {
    setIsLoading(true);
    // Simulating a delay for the loading animation
    setTimeout(() => {
      setIsLoading(false);
      onSubmit({ rating, experience, recommendations });
      setRating(0);
      setExperience('');
      setRecommendations('');
      // Navigate back to the home page ("/")
      navigate('/');
    }, 2000);
  };

  return (
    <div style={{ flex: 1, padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>{header}</h2>
      <div style={{ marginBottom: '16px' }}>
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            style={{
              cursor: 'pointer',
              color: star <= rating ? 'gold' : 'gray',
              fontSize: '24px',
              marginRight: '4px',
            }}
            onClick={() => setRating(star)}
          >
            ★
          </span>
        ))}
      </div>
      <textarea
        style={{
          width: '100%',
          maxWidth: '600px',
          padding: '16px',
          marginBottom: '16px',
          border: '1px solid black',
          borderRadius: '4px',
          resize: 'none',
          fontFamily: 'JetBrains Mono',
        }}
        rows={3}
        value={experience}
        onChange={(e) => setExperience(e.target.value)}
        placeholder="How was your experience?"
      ></textarea>
      <textarea
        style={{
          width: '100%',
          maxWidth: '600px',
          padding: '16px',
          marginBottom: '16px',
          border: '1px solid black',
          borderRadius: '4px',
          resize: 'none',
          fontFamily: 'JetBrains Mono',
        }}
        rows={3}
        value={recommendations}
        onChange={(e) => setRecommendations(e.target.value)}
        placeholder="Any recommendations?"
      ></textarea>
      <button
        style={{
          padding: '8px 16px',
          fontWeight: 'semibold',
          backgroundColor: 'white',
          color: 'black',
          border: '1px solid black',
          borderRadius: '4px',
          cursor: isLoading ? 'not-allowed' : 'pointer',
          opacity: isLoading ? 0.5 : 1,
        }}
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div
              style={{
                width: '16px',
                height: '16px',
                borderTopColor: 'black',
                borderTopWidth: '2px',
                borderRadius: '50%',
                animation: 'spin 1s linear infinite',
                marginRight: '8px',
              }}
            ></div>
            <span>Submitting...</span>
          </div>
        ) : (
          'Submit'
        )}
      </button>
    </div>
  );
};

export default Feedback;