import React, { useState, useEffect } from 'react';
import questionsData from '../data/questions_data.json';

function ProblemSearch({ onProblemSelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProblems, setFilteredProblems] = useState([]);

  useEffect(() => {
    searchProblem();
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const searchProblem = () => {
    if (searchTerm.trim() === '') {
      setFilteredProblems([]);
      return;
    }

    const filteredData = questionsData.filter(problem => {
      const questionTitle = problem.title.toLowerCase();
      const searchTermLower = searchTerm.toLowerCase();
      return questionTitle.startsWith(searchTermLower);
    });

    const limitedResults = filteredData.slice(0, 5); // Limit to 5 results
    setFilteredProblems(limitedResults);
  };

  const handleProblemClick = (problem) => {
    onProblemSelect(problem);
    setSearchTerm(''); // Reset the search term to clear the search
    setFilteredProblems([]); // Optionally clear the filtered results as well
  };

  return (
    <div style={{ fontFamily: 'JetBrains Mono', width: '100%' }}>
      <input
        className="text-black"
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Enter problem name"
        style={{ border: '1px solid black', width: '100%', padding: '8px' }}
      />
      {searchTerm.trim() !== '' && filteredProblems.length > 0 && (
        <div>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {filteredProblems.map(problem => (
              <li
                key={problem.id}
                onClick={() => handleProblemClick(problem)}
                style={{ cursor: 'pointer', padding: '8px', borderBottom: '1px solid black' }}
              >
                {problem.title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ProblemSearch;