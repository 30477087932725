import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProblemSearch from '../components/ProblemSearch.jsx';

const Home = () => {
  const navigate = useNavigate();

  const handleProblemSelect = (problem) => {
    navigate('/notebook', { state: { selectedProblem: problem } });
  };

  return (
    <div style={{ fontFamily: 'JetBrains Mono', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
      <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>Problem Search</h1>
      <ProblemSearch onProblemSelect={handleProblemSelect} />
    </div>
  );
};

export default Home;
