import React, { useState, useEffect } from 'react';
import Compiler from './Compiler';
import Textbox from './Textbox';
import Feedback from './Feedback';
import fetchOpenAI from './openai';
import Popup from './Popup';
import prompts from '../data/prompts.json';

const NotebookPhases = ({ problem, onPhaseChange, onStepComplete }) => {
    const phases = ['understandProblem', 'breakItDown', 'differentStrategies', 'outlineCode', 'implementation', 'feedback'];
    const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0);
    const [userInput, setUserInput] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const [hint, setHint] = useState('');
    const [baseCode, setBaseCode] = useState('');
    const problemString = JSON.stringify(problem);

    useEffect(() => {
        const fetchImplementationData = async () => {
            if (phases[currentPhaseIndex] === 'implementation') {
                try {
                    const baseCodeResponse = await fetchOpenAI({
                        model: 'gpt-3.5-turbo',
                        query: prompts.prompts.implementation.code.replace('{problem}', problemString),
                    });

                    const baseCodeContent = baseCodeResponse.choices[0].message.content;
                    const baseCodeJson = extractJsonFromString(baseCodeContent, true);
                    setBaseCode(baseCodeJson.code);
                    console.log("Base Code:" + JSON.stringify(baseCodeJson.code));

                } catch (error) {
                    console.error('Error fetching base code:', error);
                    setHint('An error occurred. Please try again.');
                }
            }
        };

        if (phases[currentPhaseIndex] === 'implementation') {
            fetchImplementationData();
        }
    }, [currentPhaseIndex, problemString]);

    const handleTextSubmit = async (submittedText) => {
        setUserInput((prevUserInput) => ({
            ...prevUserInput,
            [phases[currentPhaseIndex]]: submittedText,
        }));
        setAttempts((prevAttempts) => prevAttempts + 1);

        try {
            const response = await fetchOpenAI({
                model: 'gpt-3.5-turbo',
                query: prompts.prompts[phases[currentPhaseIndex]]?.prompt
                    ?.replace('{problem}', problemString)
                    ?.replace('{userInput}', submittedText) || '',
            });

            const content = response.choices[0].message.content;
            const jsonContent = extractJsonFromString(content);

            if (jsonContent.doesItPass === 'YES' || jsonContent.doesItDoIt === 'YES') {
                setShowPopup(true);
            } else {
                setHint(jsonContent.hint || '');
            }
        } catch (error) {
            console.error('Error fetching response:', error);
            setHint('An error occurred. Please try again.');
        }
    };

    const handleNextPhase = () => {
        setShowPopup(false);
        setAttempts(0);
        setHint('');

        onStepComplete(phases[currentPhaseIndex], userInput[phases[currentPhaseIndex]]);

        if (currentPhaseIndex < phases.length - 1) {
            setCurrentPhaseIndex((prevIndex) => prevIndex + 1);
        } else {
            setCurrentPhaseIndex(0);
            setUserInput({});
            onPhaseChange(phases[0]);
        }
    };

    const extractJsonFromString = (str, isImplementationPhase = false) => {
        const jsonRegex = /{([^{}]|(?:{[^{}]*})*)*}/;
        const jsonMatch = str.match(jsonRegex);

        if (jsonMatch) {
            const jsonContent = jsonMatch[0];
            try {
                const jsonObject = JSON.parse(jsonContent);
                if (isImplementationPhase && jsonObject.code) {
                    const codeWithoutBackticks = jsonObject.code.replace(/```python\n|\n```/g, '');
                    return { code: codeWithoutBackticks };
                }
                return jsonObject;
            } catch (error) {
                console.error('Error parsing JSON:', error);
                // Try to sanitize the JSON string by replacing newline characters and unescaped double quotes
                const sanitizedJsonContent = jsonContent
                    .replace(/\n/g, '\\n')
                    .replace(/"/g, '\\"');
                try {
                    return JSON.parse(sanitizedJsonContent);
                } catch (error) {
                    console.error('Error parsing sanitized JSON:', error);
                    return {};
                }
            }
        }

        return {};
    };
    

    const getComponentAndHeader = () => {
        const currentPhase = phases[currentPhaseIndex];

        switch (currentPhase) {
            case 'understandProblem':
                return { component: Textbox, header: 'Understand Problem', tips: hint || 'Make sure to read the problem carefully.' };
            case 'breakItDown':
                return { component: Textbox, header: 'Break it down into parts', tips: hint || 'Identify the main components of the problem.' };
            case 'differentStrategies':
                return { component: Textbox, header: 'Different Strategies', tips: hint || 'Consider different approaches to solve the problem.' };
            case 'outlineCode':
                return { component: Textbox, header: 'Outline Code', tips: hint || 'Create a high-level outline of your code.' };
            case 'implementation':
                return { component: Compiler, header: 'Implementation', tips: hint || 'Write clean and efficient code.', baseCode };
            case 'feedback':
                return { component: Feedback, header: 'Feedback', tips: 'Provide honest feedback about your experience.' };
            default:
                return { component: null, header: '', tips: '' };
        }
    };

    const { component: Component, header, tips, baseCode: baseCodeProp } = getComponentAndHeader();

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {Component && (
                <Component
                    header={header}
                    onSubmit={handleTextSubmit}
                    tips={tips}
                    value={userInput[phases[currentPhaseIndex]]}
                    baseCode={baseCode}
                />
            )}
            {showPopup && <Popup onNext={handleNextPhase} />}
        </div>
    );
};

export default NotebookPhases;