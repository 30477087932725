// Popup.jsx
import React from 'react';

const Popup = ({ onNext }) => {
    const handleNext = () => {
        if (onNext) {
            onNext();
        }
    };

    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <h3 style={{ marginBottom: '10px' }}>Submission Accepted</h3>
            <button
                style={{
                    padding: '8px 16px',
                    fontWeight: 'semibold',
                    backgroundColor: 'white',
                    color: 'black',
                    border: '1px solid black',
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
                onClick={handleNext}
            >
                Next
            </button>
        </div>
    );
};

export default Popup;
