import React, { useState, useEffect } from 'react';
import Editor from "@monaco-editor/react";
import axios from "axios";

const Compiler = ({ header, onSubmit, baseCode }) => {
    const [userCode, setUserCode] = useState("");
    const [userLang, setUserLang] = useState("python");
    const [userTheme, setUserTheme] = useState("vs");
    const [fontSize, setFontSize] = useState(20);
    const [userInput, setUserInput] = useState("");
    const [userOutput, setUserOutput] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (baseCode) {
            setUserCode(baseCode);
            setError(null);
        } else {
            setError("Error Pulling Base Code, Try Again");
        }
    }, [baseCode]);

    useEffect(() => {
        console.log("Base Code Received:", baseCode);
    }, [baseCode]);

    const options = {
        fontSize: fontSize,
    };

    const compile = async () => {
        setLoading(true);
        setError(null);
      
        if (!userCode) {
          setError("Please enter some code.");
          setLoading(false);
          return;
        }
      
        try {
          const response = await axios.post("https://codebrute-c1abf22e70df.herokuapp.com/compile", {
            code: userCode,
            language: userLang,
            input: userInput,
          });
      
          if (response.data && response.data.output) {
            setUserOutput(response.data.output);
          } else if (response.data && response.data.error) {
            setError(response.data.error);
          } else {
            setError("Compilation failed. Please check your code.");
          }
        } catch (err) {
          if (err.response && err.response.data && err.response.data.error) {
            setError(err.response.data.error);
          } else {
            setError("Compilation request failed. Please try again.");
          }
          console.error("Compilation error:", err);
        } finally {
          setLoading(false);
        }
      };

    const handleSubmit = () => {
        onSubmit(userCode);
    };

    const clearOutput = () => {
        setUserOutput("");
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', padding: '20px' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>{header}</h2>
            <div style={{ backgroundColor: '#f0f0f0', padding: '10px', marginBottom: '10px', fontWeight: 'bold', color: '#333' }}>
                Python Only
            </div>
            <div style={{ flex: 1, marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden' }}>
                <Editor
                    options={options}
                    height="100%"
                    width="100%"
                    theme={userTheme}
                    language={userLang}
                    defaultLanguage="python"
                    value={userCode}
                    onChange={(value) => setUserCode(value)}
                />
            </div>
            <div style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: '#f8f8f8', fontFamily: 'monospace', overflow: 'auto' }}>
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <pre style={{ color: 'red' }}>{error}</pre>
                ) : (
                    <pre>{userOutput}</pre>
                )}
                <button onClick={clearOutput} style={{ marginTop: '10px', padding: '6px 12px', borderRadius: '4px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', cursor: 'pointer' }}>
                    Clear
                </button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <button
                    style={{
                        padding: '8px 16px',
                        fontWeight: 'semibold',
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid black',
                        borderRadius: '4px',
                        cursor: loading ? 'not-allowed' : 'pointer',
                        opacity: loading ? 0.5 : 1,
                    }}
                    onClick={compile}
                    disabled={loading}
                >
                    {loading ? 'Compiling...' : 'Compile'}
                </button>
                <button
                    style={{
                        padding: '8px 16px',
                        fontWeight: 'semibold',
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid black',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default Compiler;