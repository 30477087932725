import { useState } from "react";
import "./Compiler.css";
import Editor from "@monaco-editor/react";
import axios from "axios";

function Compiler() {
  const [userCode, setUserCode] = useState("");
  const [userLang, setUserLang] = useState("python");
  const [userTheme, setUserTheme] = useState("vs-dark");
  const [fontSize, setFontSize] = useState(20);
  const [userInput, setUserInput] = useState("");
  const [userOutput, setUserOutput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const options = {
    fontSize: fontSize,
  };

  const compile = async () => {
    setLoading(true);
    setError(null);

    if (!userCode) {
      setError("Please enter some code.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post("http://localhost:8000/compile", {
        code: userCode,
        language: userLang,
        input: userInput,
      });
      setUserOutput(response.data.output);
    } catch (err) {
      setError("Compilation failed. Please try again.");
      console.error("Compilation error:", err);
    } finally {
      setLoading(false);
    }
  };

  const clearOutput = () => {
    setUserOutput("");
  };

  return (
    <div className="App">
      <div className="main">
        <div className="left-container">
          <Editor
            options={options}
            height="calc(100vh - 50px)"
            width="100%"
            theme={userTheme}
            language={userLang}
            defaultLanguage="python"
            value={userCode}
            onChange={(value) => setUserCode(value)}
          />
          <button className="run-btn" onClick={compile}>
            Run
          </button>
        </div>
        <div className="right-container">
          <h4>Input:</h4>
          <div className="input-box">
            <textarea
              id="code-inp"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
            ></textarea>
          </div>
          <h4>Output:</h4>
          <div className="output-box">
            {loading ? (
              <div className="spinner-box">Loading...</div>
            ) : error ? (
              <pre>{error}</pre>
            ) : (
              <pre>{userOutput}</pre>
            )}
            <button onClick={clearOutput} className="clear-btn">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compiler;