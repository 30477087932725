import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Notebook from './pages/Notebook';
import Compiler from './pages/Compiler';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/notebook" element={<Notebook />} />
        <Route path="/compiler" element={<Compiler />} />
      </Routes>
    </Router>
  );
}

export default App;