// openai.js
async function fetchOpenAI({ model, query }) {
  if (!model || !query) {
    console.error('Model and query are required');
    throw new Error('Model and query are required');
  }

  console.log('Fetching data...');

  try {
    const res = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer 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`,
      },
      body: JSON.stringify({
        model: model,
        messages: [{ role: 'user', content: query }],
      }),
    });

    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(`API request failed with status ${res.status}: ${errorData.error.message}`);
    }

    console.log('API response received');
    const data = await res.json();
    console.log('API Response:', data);

    return data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
}

export default fetchOpenAI;