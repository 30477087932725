// ProblemStatement.jsx
import React from 'react';

const ProblemStatement = ({ problem }) => {
  return (
    <div className="flex-1 p-5 border-r border-black overflow-y-auto bg-gray-100">
      {problem ? (
        <>
          <h1 className="text-2xl font-bold uppercase tracking-wide mb-5">{problem.title}</h1>
          <div className="mb-8">
            <strong className="text-lg">Question:</strong>
            <div className="whitespace-pre-wrap mt-2 p-4 bg-white rounded shadow">
              {problem.question}
            </div>
          </div>
          {problem.examples && problem.examples.length > 0 && (
            <div className="mb-8">
              <strong className="text-lg">Examples:</strong>
              <ul className="mt-2 space-y-2">
                {problem.examples.map((example, index) => (
                  <li
                    key={index}
                    className="p-3 bg-white rounded shadow"
                  >
                    {example}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {problem.constraints && problem.constraints.length > 0 && (
            <div>
              <strong className="text-lg">Constraints:</strong>
              <ul className="mt-2 space-y-2">
                {problem.constraints.map((constraint, index) => (
                  <li
                    key={index}
                    className="p-3 bg-white rounded shadow"
                  >
                    {constraint}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <p className="text-gray-600">Please select a problem from the home page.</p>
      )}
    </div>
  );
};

export default ProblemStatement;