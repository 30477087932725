// Notebook.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import ProblemStatement from '../components/ProblemStatement';
import NotebookPhases from '../components/NotebookPhases';

const Notebook = () => {
  const location = useLocation();
  const problem = location.state?.selectedProblem;

  const handlePhaseChange = (phase) => {
    // Handle phase change logic here
    console.log('Current Phase:', phase);
  };

  const handleStepComplete = (phase, userInput) => {
    // Handle step completion and database logging logic here
    console.log('Completed Phase:', phase);
    console.log('User Input:', userInput);
    // Example database logging logic
    // ...
  };

  return (
    <div style={{ fontFamily: 'JetBrains Mono', display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ flex: 1, display: 'flex' }}>
        <ProblemStatement problem={problem} />
        <NotebookPhases
          problem={problem}
          onPhaseChange={handlePhaseChange}
          onStepComplete={handleStepComplete}
        />
      </div>
    </div>
  );
};

export default Notebook;