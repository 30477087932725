// Textbox.jsx
import React, { useState } from 'react';

const Textbox = ({ header, onSubmit, tips }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState('');

    const handleSubmit = () => {
        if (onSubmit && !isLoading) {
            setIsLoading(true);
            onSubmit(text);
            setIsLoading(false);
        }
    };

    return (
        <div style={{ flex: 1, padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>{header}</h2>
            <textarea
                style={{
                    flex: 1,
                    width: '100%',
                    maxWidth: '600px',
                    padding: '16px',
                    marginBottom: '16px',
                    border: '1px solid black',
                    borderRadius: '4px',
                    resize: 'none',
                    fontFamily: 'JetBrains Mono',
                }}
                rows={6}
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Type your text here..."
            ></textarea>
            {tips && (
                <div
                    style={{
                        width: '100%',
                        maxWidth: '600px',
                        marginBottom: '16px',
                        padding: '16px',
                        border: '1px solid #4caf50',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <span role="img" aria-label="Lightbulb" style={{ marginRight: '8px', fontSize: '20px' }}>
                        💡
                    </span>
                    <span style={{ color: '#4caf50', fontWeight: 'semibold' }}>{tips}</span>
                </div>
            )}
            <button
                style={{
                    padding: '8px 16px',
                    fontWeight: 'semibold',
                    backgroundColor: 'white',
                    color: 'black',
                    border: '1px solid black',
                    borderRadius: '4px',
                    cursor: isLoading ? 'not-allowed' : 'pointer',
                    opacity: isLoading ? 0.5 : 1,
                }}
                onClick={handleSubmit}
                disabled={isLoading || !text.trim()}
            >
                {isLoading ? 'Submitting...' : 'Submit'}
            </button>
        </div>
    );
};

export default Textbox;
